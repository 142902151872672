import React from "react";
import imageReader from "../utils/image-reader";

function InputImage({ data, onSelect }) {
  const onImageSelected = (event) => {
    imageReader(event.target, (result) => {
      onSelect(result);
    });
    return false;
  };
  const style = data
    ? {
        backgroundImage: `url(${data})`,
        backgroundSize: "contain",
      }
    : {};
  return (
    <div className="form-group">
      <label htmlFor="image-file">Selezione l'immagine</label>

      <div className="image-preview" style={style}>
        <input
          type="file"
          className="form-control-file"
          id="image-file"
          onChange={onImageSelected}
        />
      </div>
      <div className="text-center">
        <small>(clicca sul quadrato o trascina un immagine)</small>
      </div>
    </div>
  );
}

export default InputImage;
