import React, { useState, useEffect } from "react";
import axios from "axios";

function TemplateSelector({ data, onSelect }) {
  const [templates, setTemplates] = useState({ list: [] });

  useEffect(() => {
    const fetchData = async () => {
      const result = await axios("/templates.json?r=" + Math.random());
      const list = Object.entries(result.data).map((arr) => ({
        key: arr[0],
        config: arr[1],
      }));
      setTemplates({ list });
      onSelect(list[0]);
    };

    fetchData();
  }, []);

  const onChange = (event) => {
    const key = event.target.value;
    const item = templates.list.find((item) => item.key === key);
    onSelect(item ? item : null);
  };

  if (!templates || !data) {
    return (
      <div>
        Carico template... {JSON.stringify(templates)} | {JSON.stringify(data)}{" "}
      </div>
    );
  }

  return (
    <div className="form-group">
      <label htmlFor="template-select">Template maschera</label>
      <select
        className="form-control"
        id="template-select"
        onChange={onChange}
        value={data}
      >
        {templates.list.map((item) => (
          <option key={item.key} value={item.key}>
            {item.config.title}
          </option>
        ))}
      </select>
    </div>
  );
}

export default TemplateSelector;
