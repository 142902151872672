import React from "react";

function ImagePosition({ imageSize, data, onSelect }) {
  /*
        data = {
            type: align | px
            value: {
                x: string|number
                y: string|number
            }
        }
    */
  const onChange = (event) => {
    console.log("onChange", event.target.value);
    const type = event.target.value;
    const newData = { ...data, type };
    if (type === "px") {
      newData.value.x = "0px";
      newData.value.y = "0px";
    } else {
      newData.value.x = "center";
      newData.value.y = "center";
    }
    onSelect(newData);
    return false;
  };
  const onChangeX = (event) => {
    const p = event.target.value;
    const value = { ...data.value };
    value.x = p;
    const newData = { ...data, value };
    onSelect(newData);
    return false;
  };
  const onChangeY = (event) => {
    const p = event.target.value;
    const value = { ...data.value };
    value.y = p;
    const newData = { ...data, value };
    onSelect(newData);
    return false;
  };

  const resetPosition = () => {
    const value = { ...data.value };
    value.y = 0;
    value.x = 0;
    const newData = { ...data, value };
    onSelect(newData);
    return false;
  };

  const positionByAlign = () => {
    return (
      <div className="form-group">
        <label>Orizzontale</label>
        <select
          className="form-control"
          onChange={onChangeX}
          value={data.value.x}
        >
          <option value="left">Sinistra</option>
          <option value="center">Centro</option>
          <option value="right">Destra</option>
        </select>
        <label>Verticale</label>
        <select
          className="form-control"
          onChange={onChangeY}
          value={data.value.y}
        >
          <option value="top">Alto</option>
          <option value="center">Centro</option>
          <option value="bottom">Basso</option>
        </select>
      </div>
    );
  };

  const pixelInfo = () => {
    return (
      <div style={{ marginTop: 20 }}>
        <div>
          Posizioni: {data.value.x} | {data.value.y}{" "}
        </div>
        <button
          className="btn btn-secondary"
          style={{ marginTop: 20 }}
          onClick={resetPosition}
        >
          Reset posizione
        </button>
      </div>
    );
  };

  return (
    <div>
      <div className="form-group">
        <label htmlFor="template-select">Posizione immagine</label>
        <select
          className="form-control"
          id="template-select"
          onChange={onChange}
          value={data.type}
        >
          <option value="align">Allineamento</option>
          <option value="px">Sposta col mouse</option>
        </select>
        {data.type === "align" ? positionByAlign() : pixelInfo()}
      </div>
    </div>
  );
}

export default ImagePosition;

/*
<div className="form-group">
      <label htmlFor="image-file">Selezione l'immagine</label>

      <div className="image-preview" style={style}>
      <input
        type="file"
        className="form-control-file"
        id="image-file"
        onChange={onImageSelected}
      />
      </div>
      <div className="text-center">
      <small>(clicca sul quadrato o trascina un immagine)</small>
      </div>
    </div>
*/
