import React from "react";

function ImageSizeMode({ data, onSelect }) {
  const modes = [
    { prop: "cover", key: "mode-cover", label: "Copri tutta l'area" },
    {
      prop: "contain",
      key: "mode-contain",
      label: "Adatta all'area disponile",
    },
    { prop: "auto", key: "mode-auto", label: "Dimensioni reali" },
    { prop: "custom", key: "mode-custom", label: "Custom" },
  ];

  const onChange = (event) => {
    const key = event.target.value;
    const item = modes.find((item) => item.prop === key);
    if (!item) {
      return;
    }
    if (isCustom(key)) {
      onSelect("100%");
    } else {
      onSelect(item.prop);
    }
  };

  const isCustom = (d) => {
    const types = modes.map((i) => i.prop);
    types.pop();
    return types.indexOf(d) === -1;
  };

  const mappedData = isCustom(data) ? "custom" : data;

  const changeScale = (event) => {
    const v = event.target.value;
    onSelect(`${v}%`);
  };

  return (
    <div>
      <div className="form-group">
        <label htmlFor="template-select">Disposizione immagine</label>
        <select
          className="form-control"
          id="image-size-mode"
          onChange={onChange}
          value={mappedData}
        >
          {modes.map((item) => (
            <option key={item.key} value={item.prop}>
              {item.label}
            </option>
          ))}
        </select>
      </div>
      {mappedData === "custom" && (
        <div className="form-group">
          <label htmlFor="formControlRange">Scala immagine: {data}</label>
          <input
            onChange={changeScale}
            type="range"
            className="form-control-range"
            id="formControlRange"
            min="10"
            max="500"
          />
        </div>
      )}
    </div>
  );
}

export default ImageSizeMode;
