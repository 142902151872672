import React, { useState } from "react";
import netstradaLogo from "./netstrada.svg";
import "./App.css";
import "./styles/sidebar.css";
import TemplateSelector from "./components/template-selector";
import ImageSizeMode from "./components/image-size-mode";
import InputImage from "./components/input-image";
import ImagePosition from "./components/image-position";
import domtoimage from "dom-to-image";
import _ from "lodash";
import moment from "moment";

function App() {
  const [data, setData] = useState({
    sizeMode: "cover",
    imagePosition: {
      type: "align",
      value: {
        x: "center",
        y: "center",
      },
    },
    image: {},
    backgroundStyle: {},
    foregroundStyle: {},
    targetImageStyle: {},
    targetImage: null,
  });
  console.log("Carico", data);

  const onTemplateSelect = (item) => {
    if (!item) {
      alert("Errore nella selezione del template");
      return;
    }
    setData({
      ...data,
      image: item,
      backgroundStyle: { backgroundImage: `url(${item.config.background})` },
      foregroundStyle: { backgroundImage: `url(${item.config.foreground})` },
    });
  };

  const onImageSizeSelect = (sizeMode) => {
    console.log("sizeMode", sizeMode);
    if (_.get(data, "targetImageStyle.backgroundSize") === sizeMode) {
      return;
    }
    if (!data.targetImage) {
      return;
    }

    setData({
      ...data,
      sizeMode,
      targetImageStyle: {
        backgroundSize: sizeMode,
        backgroundImage: `url(${data.targetImage})`,
        backgroundPosition: `${data.imagePosition.value.x} ${data.imagePosition.value.y}`,
      },
    });
  };

  const onImageSelect = (imageData) => {
    setData({
      ...data,
      targetImage: imageData,
      targetImageStyle: {
        backgroundSize: data.sizeMode,
        backgroundImage: `url(${imageData})`,
        backgroundPosition: `${data.imagePosition.value.x} ${data.imagePosition.value.y}`,
      },
    });
  };

  const onImagePosition = (positionData) => {
    if (!data.targetImage) {
      return;
    }
    const targetImageStyle = {
      ...data.targetImageStyle,
      backgroundPosition: `${positionData.value.x} ${positionData.value.y}`,
    };
    setData({
      ...data,
      imagePosition: positionData,
      targetImageStyle,
    });
  };

  const draggingInfo = {
    x: 0,
    y: 0,
    targetX: 0,
    targetY: 0,
    moving: false,
  };
  const onMousDown = (event) => {
    if (data.imagePosition.type !== "px") {
      return;
    }
    // console.log("onMousDown", event)
    draggingInfo.moving = true;
    draggingInfo.x = event.screenX;
    draggingInfo.y = event.screenY;
    draggingInfo.targetX = parseInt(data.imagePosition.value.x);
    draggingInfo.targetY = parseInt(data.imagePosition.value.y);
  };
  const onMouseMove = (event) => {
    if (!draggingInfo.moving || data.imagePosition.type !== "px") {
      return;
    }

    const x = draggingInfo.targetX + (event.screenX - draggingInfo.x);
    const y = draggingInfo.targetY + (event.screenY - draggingInfo.y);
    // const imagePosition = {...data.imagePosition, value:{x:`${x}px`,y:`${y}px` }}
    const node = document.getElementById("target-image");
    if (node) {
      node.style.backgroundPosition = `${x}px ${y}px`;
    }
    // console.log("onMouseMove", x, y)
  };
  const onMouseUp = (event) => {
    if (!draggingInfo.moving || data.imagePosition.type !== "px") {
      return;
    }
    // console.log("onMouseUp", event)
    draggingInfo.moving = false;
    const x = draggingInfo.targetX + (event.screenX - draggingInfo.x);
    const y = draggingInfo.targetY + (event.screenY - draggingInfo.y);
    const imagePosition = {
      ...data.imagePosition,
      value: { x: `${x}px`, y: `${y}px` },
    };
    onImagePosition(imagePosition);
  };

  const saveImage = async () => {
    const node = document.getElementById("image");
    if (node) {
      node.setAttribute("class", "");
      const dataUrl = await domtoimage.toJpeg(node, { quality: 0.8 });
      var link = document.createElement("a");
      var str = moment().format("YYYYMMDD");
      link.download = str + "-senigallia-notizie-telegiornale.jpg";
      link.href = dataUrl;
      link.click();

      node.setAttribute("class", "preview");
    } else {
      alert("Non e' stato possibile salvare l'immagine");
    }
  };

  return (
    <div className="main" onMouseMove={onMouseMove} onMouseUp={onMouseUp}>
      <div id="side-bar">
        <div className="logo-container">
          <img src={netstradaLogo} className="logo" alt="Netstrada" />
          <div>SOLUTIONS</div>
        </div>
        <div className="controls">
          <TemplateSelector
            onSelect={onTemplateSelect}
            data={_.get(data, "image.key", null)}
          />
          <InputImage onSelect={onImageSelect} data={data.targetImage} />
          {data.targetImage && (
            <div>
              <ImageSizeMode
                onSelect={onImageSizeSelect}
                data={data.sizeMode}
              />
              <ImagePosition
                imageSize={data.sizeMode}
                data={data.imagePosition}
                onSelect={onImagePosition}
              />

              <hr />
              <button className="btn btn-primary" onClick={saveImage}>
                Salva immagine
              </button>
            </div>
          )}
        </div>
      </div>
      <div id="workarea">
        <div id="image-title">
          La maschera e' trasperante per una gestione migliore, durante il
          salataggio sara' completamente visibile.
        </div>
        <div id="image" className="preview" onMouseDown={onMousDown}>
          <div
            id="background-image"
            style={data.backgroundStyle}
            className="fullsize-image"
          ></div>
          <div
            id="target-image"
            style={data.targetImageStyle}
            className="fullsize-image"
          ></div>
          <div
            id="foreground-image"
            style={data.foregroundStyle}
            className="fullsize-image"
          ></div>
        </div>
      </div>
    </div>
  );
}

export default App;
