function imageReader(input, callback) {
  if (input.files && input.files[0]) {
    var reader = new FileReader();

    reader.onload = function (e) {
      if (!e || !e.target) {
        return null;
      }
      callback(e.target.result);
      // $('#target-image').css('background-image', 'url(' + e.target.result + ')');
    };

    reader.readAsDataURL(input.files[0]);
  }
}
export default imageReader;
